import type { Node as PMNode, Schema } from '@atlaskit/editor-prosemirror/model';
import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import { getPluginState, pluginKey } from './plugin-key';
import { DataSourceProvider } from '../data-source-provider';
import { NodeMutationObserver } from '../node-mutation-observer';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import type { ExtensionPluginOptions } from '@atlaskit/editor-plugin-extension';
import { fg } from '@atlaskit/platform-feature-flags';
import type { NextEditorPlugin, ExtractInjectionAPI } from '@atlaskit/editor-common/types';
import type { EditorProps } from '@atlaskit/editor-core';
import type { EventDispatcher } from '@atlaskit/editor-common/event-dispatcher';
import { getChangedNodes } from '@atlaskit/editor-common/utils';
import ExtensionNodeView from '../nodeviews/extension';
import type {
	ExternalObserver,
	AttachedEditorView,
	ReferentialityAPI,
	ReferentialityAPIOptions,
	ReferentialityPluginState,
} from '../types';
import { ADFReferenceTypes } from '../types';
import { ReferentialityAPIImpl } from '../referentiality-api';
import {
	findChildrenByType,
	findParentNodeOfType,
	findSelectedNodeOfType,
} from '@atlaskit/editor-prosemirror/utils';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import memoizeOne from 'memoize-one';
import { getNodeTypesSupported } from '../utils';
import { MACRO_INTERACTION_DESIGN_UPDATES } from '../featureFlags';
import type { DataConsumerPlugin } from '@atlaskit/editor-plugin-data-consumer';
import type { WidthPlugin } from '@atlaskit/editor-plugin-width';
import { type PortalProviderAPI } from '@atlaskit/editor-common/portal';
import type { MacroInteractionDesignFeatureFlags } from '@atlaskit/editor-common/extensibility';
type ExtendedReferentialityAPI = AttachedEditorView & ReferentialityAPI;

const EmitterEvents = { TABLE_DELETED: 'TABLE_DELETED' };

export const check = (node: PMNode): boolean => {
	try {
		node.check();
		return true;
	} catch (err) {
		return false;
	}
};

const allowedSource = (node: PMNode | ADFEntity, schema: Schema): boolean => {
	const nodeName = typeof node.type === 'string' ? node.type : node.type.name;
	return [schema.nodes.table?.name].includes(nodeName) && node.attrs?.localId;
};

const transformer = new JSONTransformer();
const memoizedEncodeNode = memoizeOne((node: PMNode) => transformer.encodeNode(node));

function createPlugin(
	providerFactory: ProviderFactory,
	extensionHandlers: ExtensionHandlers,
	portalProviderAPI: PortalProviderAPI,
	eventDispatcher: EventDispatcher,
	externalObservers: ExternalObserver[],
	options: {
		appearance?: NonNullable<EditorProps['appearance']>;
	} = {},
	pluginInjectionApi: ExtractInjectionAPI<typeof referentialityPlugin> | undefined,
	api?: ExtendedReferentialityAPI,
	featureFlags?: EditorProps['featureFlags'],
	__livePage?: boolean,
	__rendererExtensionOptions?: Options['__rendererExtensionOptions'],
) {
	const macroInteractionDesignFeatureFlags: MacroInteractionDesignFeatureFlags = {
		showMacroInteractionDesignUpdates: !!featureFlags?.[MACRO_INTERACTION_DESIGN_UPDATES],
	};
	const showLivePagesBodiedMacrosRendererView =
		__rendererExtensionOptions?.isAllowedToUseRendererView;

	return new SafePlugin<ReferentialityPluginState>({
		key: pluginKey,
		state: {
			init() {
				const dataSourceProvider = new DataSourceProvider();
				const nodeMutationObserver = new NodeMutationObserver(dataSourceProvider);
				externalObservers.forEach((externalObserver) =>
					externalObserver.init({
						emit: (localId, data) => dataSourceProvider.createOrUpdate(localId, data),
					}),
				);

				return {
					dataSourceProvider,
					nodeMutationObserver,
					changedSources: new Map(),
					selectedNodeLocalId: undefined,
				};
			},
			apply(tr, pluginState, state, ns) {
				let selectedNodeLocalId: string | undefined = pluginState.selectedNodeLocalId;

				if (!!api && !state.selection.eq(ns.selection)) {
					const supportedNodeTypes = getNodeTypesSupported(state.schema);
					const { fragment } = state.schema.marks;
					const selectedNode =
						findSelectedNodeOfType(supportedNodeTypes)(tr.selection) ||
						findParentNodeOfType(supportedNodeTypes)(tr.selection);

					selectedNodeLocalId =
						selectedNode?.node &&
						(fragment?.isInSet(selectedNode?.node.marks)?.attrs.localId ||
							selectedNode?.node.attrs.localId);
				}

				const selectedLocalIdChanged = selectedNodeLocalId !== pluginState.selectedNodeLocalId;

				if (!tr.docChanged) {
					return selectedLocalIdChanged ? { ...pluginState, selectedNodeLocalId } : pluginState;
				}

				const changedSources = new Map();

				for (const [pos, node] of pluginState.changedSources.entries()) {
					const newPos = tr.mapping.map(pos);
					changedSources.set(newPos, node);
				}

				// Handle added/updated table node
				const changedNodes = getChangedNodes(tr).filter(({ node }) => {
					// Since we are calling node validation with every interaction in useDispatchTransaction, we can safely drop the check node here to improve performance.
					// More details: https://hello.atlassian.net/wiki/spaces/~70121ba36f99acdb4453d8f80fd8186963f3e/pages/5081212079/Optimising+typing+latency#Bonus
					if (fg('cc_complexit_fe_skip_node_check_in_referentiality')) {
						return tr.getMeta('uiEvent') !== 'cut' && allowedSource(node, state.schema);
					}
					return (
						tr.getMeta('uiEvent') !== 'cut' && allowedSource(node, state.schema) && check(node)
					);
				});

				changedNodes.forEach(({ node: sourceNode, pos }) => {
					changedSources.set(pos, sourceNode);
				});

				if (changedNodes.length) {
					return { ...pluginState, changedSources, selectedNodeLocalId };
				}

				if (selectedLocalIdChanged) {
					return { ...pluginState, selectedNodeLocalId };
				}

				return pluginState;
			},
		},
		view: (editorView) => {
			api?.attach(editorView);

			const tableNodeSchema = editorView.state.schema.nodes.table;
			const pluginState = getPluginState(editorView.state);
			if (!pluginState) {
				return {};
			}
			let handleDeleteDataSource: (node: PMNode) => void;
			const dataSourceProvider = pluginState.dataSourceProvider;
			const nodeMutationObserver = pluginState.nodeMutationObserver;

			if (dataSourceProvider) {
				handleDeleteDataSource = (node) => {
					if (node && node.attrs) {
						let tableExistsInDoc = false;
						// ensure the destroyed node is actually not recreated
						findChildrenByType(editorView.state.doc, tableNodeSchema).forEach(
							({ node: existingNode }) => {
								if (existingNode.attrs.localId === node.attrs.localId) {
									tableExistsInDoc = true;
								}
							},
						);

						if (!tableExistsInDoc) {
							dataSourceProvider.delete(node.attrs.localId);
						}
					}
				};
				eventDispatcher.on(EmitterEvents.TABLE_DELETED, handleDeleteDataSource);
			}

			if (nodeMutationObserver) {
				findChildrenByType(editorView.state.doc, tableNodeSchema).forEach(({ node }) => {
					const fragmentLocalId = node?.marks?.find((m) => m.type.name === 'fragment')?.attrs
						?.localId;

					if (fragmentLocalId) {
						nodeMutationObserver.emit(fragmentLocalId, {
							get [ADFReferenceTypes.TABLE]() {
								return memoizedEncodeNode(node);
							},
						});
					}

					nodeMutationObserver.emit(node.attrs.localId, {
						get [ADFReferenceTypes.TABLE]() {
							return memoizedEncodeNode(node);
						},
					});
				});
			}

			return {
				update(view, prevState) {
					const pluginState = getPluginState(view.state);
					if (!pluginState) {
						return;
					}

					const prevPluginState = getPluginState(prevState);

					const { changedSources, nodeMutationObserver, selectedNodeLocalId } = pluginState;

					if (selectedNodeLocalId !== prevPluginState?.selectedNodeLocalId) {
						api?.setSelectedLocalId(selectedNodeLocalId);
					}

					changedSources.forEach((node: PMNode, pos: number) => {
						const fragmentLocalId = node?.marks?.find((m) => m.type.name === 'fragment')?.attrs
							?.localId;

						if (fragmentLocalId) {
							nodeMutationObserver.emit(fragmentLocalId, {
								get [ADFReferenceTypes.TABLE]() {
									return memoizedEncodeNode(node);
								},
							});
						}

						if (node.attrs?.localId) {
							nodeMutationObserver.emit(node.attrs.localId, {
								get [ADFReferenceTypes.TABLE]() {
									return memoizedEncodeNode(node);
								},
							});
						}
					});

					changedSources.clear();
				},
				destroy() {
					api?.detach();

					if (dataSourceProvider) {
						eventDispatcher.off(EmitterEvents.TABLE_DELETED, handleDeleteDataSource);

						if (dataSourceProvider.clear) {
							dataSourceProvider.clear();
						}
					}
				},
			};
		},
		props: {
			nodeViews: {
				// WARNING: these need to be kept in sync with the nodeViews
				// setup in the extension plugin.
				extension: ExtensionNodeView(
					portalProviderAPI,
					eventDispatcher,
					providerFactory,
					extensionHandlers,
					options.appearance,
					pluginInjectionApi,
					macroInteractionDesignFeatureFlags,
				),
				// WARNING: these need to be kept in sync with the nodeViews
				// setup in the extension plugin.
				bodiedExtension: ExtensionNodeView(
					portalProviderAPI,
					eventDispatcher,
					providerFactory,
					extensionHandlers,
					options.appearance,
					pluginInjectionApi,
					macroInteractionDesignFeatureFlags,
					showLivePagesBodiedMacrosRendererView,
					__rendererExtensionOptions?.showUpdated1PBodiedExtensionUI,
					__rendererExtensionOptions?.rendererExtensionHandlers,
				),
				// WARNING: these need to be kept in sync with the nodeViews
				// setup in the extension plugin.
				inlineExtension: ExtensionNodeView(
					portalProviderAPI,
					eventDispatcher,
					providerFactory,
					extensionHandlers,
					options.appearance,
					pluginInjectionApi,
					macroInteractionDesignFeatureFlags,
				),
			},
		},
	});
}

type Options = ReferentialityAPIOptions & {
	extensionHandlers?: ExtensionHandlers;
	externalObservers?: ExternalObserver[];
	api?: ExtendedReferentialityAPI;
	appearance?: NonNullable<EditorProps['appearance']>;
	__rendererExtensionOptions?: ExtensionPluginOptions['__rendererExtensionOptions'];
};

/**
 * Referentiality plugin to be added to an `EditorPresetBuilder` and used with `ComposableEditor`
 * from `@atlaskit/editor-core`.
 */
export const referentialityPlugin: NextEditorPlugin<
	'referentiality',
	{
		pluginConfiguration: Options | undefined;
		dependencies: [WidthPlugin, DataConsumerPlugin];
	}
> = ({ config: options, api: pluginInjectionApi }) => ({
	name: 'referentiality',
	pmPlugins() {
		return [
			{
				name: 'referentiality',
				plugin: ({
					providerFactory,
					portalProviderAPI,
					eventDispatcher,
				}: {
					providerFactory: ProviderFactory;
					portalProviderAPI: PortalProviderAPI;
					eventDispatcher: EventDispatcher;
				}) => {
					const extensionHandlers = options?.extensionHandlers ?? {};
					const externalObservers = options?.externalObservers ?? [];

					return createPlugin(
						providerFactory,
						extensionHandlers,
						portalProviderAPI,
						eventDispatcher,
						externalObservers,
						{ appearance: options?.appearance },
						pluginInjectionApi,
						options?.api,
						options?.featureFlags,
						options?.__livePage,
						options?.__rendererExtensionOptions,
					);
				},
			},
		];
	},
});

export const createReferentiality = (
	options?: Options,
): {
	api: ExtendedReferentialityAPI;
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	plugin: ReturnType<NextEditorPlugin<any, any>>;
} => {
	const api = options?.api ?? new ReferentialityAPIImpl(options);
	return { api, plugin: referentialityPlugin({ config: { ...options, api } }) };
};
